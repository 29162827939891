import {createRouter, createWebHashHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue')
    },
    {
        path: '/certified',
        name: 'certified',
        component: () => import('@/views/CertifiedView.vue')
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import('@/views/ShopView.vue')
    },
    {
        path: '/bag/:bag_type',
        name: 'bag',
        component: () => import('@/views/BagView.vue'),
        props: true
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('@/views/ProductView.vue')
    },
    {
        path: '/admin',
        name: "admin",
        component: () => import('@/views/AdminView.vue'),
        children: [
            {
                path: 'login',
                name: 'admin_login',
                component: () => import('@/components/admin/LoginComponent.vue')
            },
            {
                path: "earth",
                name: "admin_earth",
                component: () => import('@/components/admin/EarthComponent.vue')
            },
            {
                path: "home_shop",
                name: "admin_home_shop",
                component: () => import('@/components/admin/HomeShopComponent.vue')
            },
            {
                path: "product",
                name: "admin_product",
                component: () => import('@/components/admin/ProductComponent.vue')
            },
            {
                path: 'contact',
                name: 'admin_contact',
                component: () => import('@/components/admin/ContactComponent.vue')
            },
            {
                path: 'partner',
                name: 'admin_partner',
                component: () => import('@/components/admin/PartnerComponent.vue')
            },
            {
                path: "about",
                name: 'admin_about',
                component: () => import('@/components/admin/AboutComponent.vue')
            },
            {
                path: "certified",
                name: 'admin_certified',
                component: () => import('@/components/admin/CertifiedComponent.vue')
            },
            {
                path: "shop",
                name: 'admin_shop',
                component: () => import('@/components/admin/ShopComponent.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    }
})

router.beforeEach((to, from, next) => {
    if ((to.name as string).includes("admin") && to.name != "admin_login" && !store.state.isLogin) {
        next({name: 'admin_login'});
    } else {
        next();
    }
});


export default router;
