import {createStore} from 'vuex'

const store = createStore({
    state: {
        isLogin: false,
        contactEmail: ""
    },
    getters: {
        isLogin(state) {
            return state.isLogin
        },
        contactEmail(state) {
            return state.contactEmail
        },
    },
    mutations: {
        setContactEmail(state, contactEmail) {
            state.contactEmail = contactEmail;
        },
        setLoginState(state, isLogin) {
            state.isLogin = isLogin;
            sessionStorage.setItem("isLogin", String(isLogin));
        },
        syncSessionLoginState(state) {
            const isLogin = sessionStorage.getItem("isLogin");

            if (isLogin)
                state.isLogin = JSON.parse(isLogin);
        }
    },
    actions: {},
    modules: {}
});

export default store
